<template>
    <div class="business">      
        <div class="container">            
            <div class="banner">
                <div class="auto-container">
                    <h1>商务</h1>
                    <p>首页<Icon class="arrow" type="ios-arrow-forward" />商务</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import business from "./index.js";
export default business;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>